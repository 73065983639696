import { Dialog, Transition } from "@headlessui/react";
import { BaseFilter, BaseFilterString } from "entities/BaseEntities";
import { BaseProp } from "entities/BaseProp";
import {
  InventoryItem,
  ProductTypeFilterElement,
  ProductTypeFilterParams,
} from "entities/ProductTypeDTO";
import React, { FC, Fragment, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setClearProductFilter,
  setProductFilter,
} from "store/slices/productTypeSlice";
import handleScrollToEl from "utils/HandleScrollToEl";

interface ProductSideFiltersProp extends BaseProp {
  inventoryData?: InventoryItem[];
  handleBreadCrumb?: (key: string, value: string | null | undefined) => void;
}

const ProductSideFilters: FC<ProductSideFiltersProp> = ({
  className = "",
  inventoryData,
  handleBreadCrumb,
}) => {
  const dispatch = useAppDispatch();
  const _storeData = useAppSelector((state) => state.productType.data?.data);
  const _storeFilter = useAppSelector((state) => state.productType.filter);

  const appliedFilters = [
    _storeFilter.ptypeId,
    _storeFilter.pserId,
    _storeFilter.deptId,
    _storeFilter.wattId,
    _storeFilter.voltId,
    _storeFilter.ctempId,
    _storeFilter.fcolorId,
    _storeFilter.tradesizeId,
    _storeFilter.certId,
  ].filter((value) => value !== undefined).length;

  const {
    departmentList,
    productTypeList,
    productSeriesList,
    wattageList,
    voltageList,
    colorTemperatureList,
    finishColorList,
    tradeSizeList,
    certificationsList,
  } = _storeData || {};

  const defaultFilters: ProductTypeFilterElement = {
    departmentList: departmentList || [],
    productTypeList: productTypeList || [],
    productSeriesList: productSeriesList || [],
    wattageList: wattageList || [],
    voltageList: voltageList || [],
    colorTemperatureList: colorTemperatureList || [],
    finishColorList: finishColorList || [],
    tradeSizeList: tradeSizeList || [],
    certificationsList: certificationsList || [],
  };

  const [dataResult, setDataResult] =
    useState<ProductTypeFilterElement>(defaultFilters);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const applyFilter = <T extends BaseFilter | BaseFilterString>(
    filterList: T[],
    filterIds: (number | string)[],
    filterKey: keyof ProductTypeFilterElement
  ) => {
    // Derived Inventory Obj
    const filteredList = filterList.filter((x) =>
      filterIds.includes(x.filterId)
    );
    setDataResult((prevFilters) => ({
      ...prevFilters,
      [filterKey]: filteredList,
    }));
  };

  const checkDefaultFilter = () => {
    // Derived Inventory Obj
    const filterInv = inventoryData;

    // Department
    applyFilter(
      departmentList || [],
      filterInv?.flatMap(x=>x.mapDeptArr || []) || [],
      "departmentList"
    );

    // Product Type
    applyFilter(
      productTypeList || [],
      filterInv?.flatMap(x=>x.mapProdTypeArr || []) || [],
      "productTypeList"
    );

    // Product Series
    applyFilter(
      productSeriesList || [],
      filterInv?.flatMap(x=>x.mapProdSeriesArr || []) || [],
      "productSeriesList"
    );

    // Wattage
    applyFilter(
      wattageList || [],
      filterInv?.map((x) => x.wattage) || [],
      "wattageList"
    );
    //Voltage
    applyFilter(
      voltageList || [],
      filterInv?.map((x) => x.voltage) || [],
      "voltageList"
    );
    applyFilter(
      colorTemperatureList || [],
      filterInv?.map((x) => x.colorTemp) || [],
      "colorTemperatureList"
    );
    applyFilter(
      finishColorList || [],
      filterInv?.map((x) => x.finishColor) || [],
      "finishColorList"
    );
    applyFilter(
      tradeSizeList || [],
      filterInv?.map((x) => x.tradeSizeId) || [],
      "tradeSizeList"
    );
    applyCertificateFilter(filterInv || []);
  };

  const applyCertificateFilter = (filterInv: InventoryItem[]) => {
    const cert = filterInv?.map((x) => x.certification).join(",") || "";
    const certArray = cert.split(",").map((item) => item.trim());
    const uniqueCertArray = certArray.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    applyFilter(
      certificationsList || [],
      uniqueCertArray,
      "certificationsList"
    );
  };

  const assignBreadCrumbs = (
    breadCrumbType: string,
    filterArr?: BaseFilter[],
    filterId?: number
  ) => {
    const filterValue = filterId
      ? filterArr?.find((x) => x.filterId === filterId)?.filterName
      : filterArr?.length === 1
      ? filterArr?.[0].filterName
      : undefined;

    handleBreadCrumb?.(breadCrumbType, filterValue);
  };

  useEffect(() => {
    assignBreadCrumbs("department", dataResult.departmentList, _storeFilter.deptId);
    assignBreadCrumbs("productType", dataResult.productTypeList, _storeFilter.ptypeId);
    assignBreadCrumbs("productSeries", dataResult.productSeriesList, _storeFilter.pserId);
  }, [dataResult,_storeFilter]);

  useEffect(() => {
    if (appliedFilters > 0) {
      checkDefaultFilter();
    } else {
      setDataResult(defaultFilters);
    }
  }, [inventoryData]);

  const handleCheckboxChange = (
    check: boolean,
    filterId: any,
    filterProp: keyof ProductTypeFilterParams
  ) => {
    const filterVal = check ? filterId : undefined;
    dispatch(setProductFilter({ key: filterProp, value: filterVal }));
  };

  //String filter function
  const renderTabsFilters = <T extends BaseFilter | BaseFilterString>(
    items: T[],
    title: string,
    filterProp: keyof ProductTypeFilterParams
  ) => {
    return (
      <>
        {items.length > 0 && (
          <div className="relative flex flex-col pb-4">
            <h3 className="font-semibold my-2.5 px-1.5">{title}</h3>
            <div className="max-h-[245px] overflow-y-auto space-y-4 px-1.5 py-1.5 customScrollBar">
              {items.map((item, index) => (
                <div key={`div${item.filterName}${item.filterId}`} className="">
                  <Checkbox
                    key={`chk${item.filterName}${item.filterId}`}
                    name={`chk${item.filterName}${item.filterId}`}
                    label={item.filterName}
                    checked={
                      items.length === 1 ||
                      (_storeFilter[filterProp] &&
                        _storeFilter[filterProp] === item.filterId)
                        ? true
                        : false
                    }
                    sizeClassName="w-5 h-5"
                    labelClassName="text-sm font-normal hover:translate-x-1 transition duration-500 hover:text-csccolor"
                    onChange={(checked) =>
                      handleCheckboxChange(checked, item.filterId, filterProp)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const renderProductFilters = () => {
    return (
      <>
        <div>
          <button
            onClick={() => {
              dispatch(setClearProductFilter());
            }}
            className="w-full py-2
            text-black dark:text-neutral-200 
            border border-neutral-200 dark:border-neutral-700 
            hover:bg-neutral-100 rounded-2xl px-4 
            font-medium text-sm bg-neutral-200/70 
            dark:bg-neutral-700 dark:hover:bg-neutral-800 flex justify-center items-center transition-colors
            halloween:bg-white halloween:text-black halloween:hover:bg-neutral-100"
          >
            Clear all filters
          </button>
        </div>
        {dataResult && (
          <div
            className={`divide-y divide-slate-200 dark:divide-slate-700 halloween:divide-white ${className}`}
          >
            <div className="py-4 pr-2">
              <span className="text-xl font-medium">Filters</span>
            </div>
            {renderTabsFilters(
              dataResult.departmentList,
              `Department`,
              "deptId"
            )}
            {renderTabsFilters(
              dataResult.productTypeList,
              `Product Type`,
              "ptypeId"
            )}
            {renderTabsFilters(
              dataResult.productSeriesList,
              `Product Series`,
              "pserId"
            )}
            {renderTabsFilters(dataResult.wattageList, `Wattage`, "wattId")}
            {renderTabsFilters(dataResult.voltageList, `Voltage`, "voltId")}
            {renderTabsFilters(
              dataResult.colorTemperatureList,
              `Color Temperature`,
              "ctempId"
            )}
            {renderTabsFilters(
              dataResult.finishColorList,
              `Finish Color`,
              "fcolorId"
            )}
            {renderTabsFilters(
              dataResult.tradeSizeList,
              `Trade Size`,
              "tradesizeId"
            )}
            {renderTabsFilters(
              dataResult.certificationsList,
              `Certifications`,
              "certId"
            )}
          </div>
        )}
      </>
    );
  };

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none
          ${
            appliedFilters > 0
              ? "!border-primary-500 bg-primary-50 text-primary-900"
              : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
          }
          `}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="ml-2">Product filters ({appliedFilters})</span>
          {appliedFilters > 0 && renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Product filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">{renderProductFilters()}</div>
                      {/* --------- */}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        dispatch(setClearProductFilter());
                        closeModalMoreFilter();
                      }}
                      sizeClass="py-2.5 px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="py-2.5 px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <>
      {dataResult && (
        <>
          <div className="hidden lg:flex lg:flex-col pr-4">
            {renderProductFilters()}
          </div>
          {/* FOR RESPONSIVE MOBILE */}
          <div className="flex flex-col overflow-x-auto lg:hidden space-y-4">
            {renderTabMobileFilter()}
          </div>
        </>
      )}
    </>
  );
};

export default ProductSideFilters;
