import { faBoxOpen, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import {
  ClearanceDTO,
  ClearanceFilterParameter,
  ProductDataList,
} from "entities/ClearanceDTO";
import React, { FC, Fragment, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import FilterRadioComponent from "./FilterRadioComponent";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Radio from "shared/Radio/Radio";
import { BaseFilter } from "entities/BaseEntities";
import { BaseProp } from "entities/BaseProp";
import { useBackgroundContext } from "hooks/useBackgroundContext";

interface FilterClearanceProp extends BaseProp {
  data: ClearanceDTO;
  filterParam: ClearanceFilterParameter;
  filteredItems?: ProductDataList[];
  labelTitle:string;
  handleFilterChange: (
    filterName: string,
    value: number | string | undefined
  ) => void;
}

const FilterClearance: FC<FilterClearanceProp> = ({
  className = "",
  data,
  filterParam,
  filteredItems,
  labelTitle="Filters",
  handleFilterChange,
}) => {
  {
    /**State Hook */
  }
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [filterProductTypeList, setFilterProductTypeList] = useState<
    BaseFilter[]
  >(data.productTypeList);
  const [filterProductSeriesList, setFilterProductSeriesList] = useState<
    BaseFilter[]
  >(data.productSeriesList);

  const {isBackgroundImage} = useBackgroundContext();

  {
    /**Decentralised object */
  }
  const { productSeriesList, productTypeList } = data;
  const { productTypeId, productSeriesId, searchText } = filterParam;
  const appliedFilters = [productTypeId, productSeriesId].filter(
    (value) => value !== 0
  ).length;

  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderSearch = () => {
    return (
      <form className="relative w-full" onSubmit={(e) => e.preventDefault()}>
        <label
          htmlFor="search-input"
          className="text-neutral-500 dark:text-neutral-300"
        >
          <Input
            className="shadow-lg border-0 dark:border"
            id="search-input"
            type="search"
            placeholder="search"
            sizeClass="pl-14 py-2 pr-5 md:pl-16"
            rounded="rounded-full"
            autoComplete="Off"
            onChange={(e) => handleFilterChange("searchText", e.target.value)}
          />
          <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
            <svg
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </label>
      </form>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full halloween:bg-white border focus:outline-none cursor-pointer select-none
          ${
            appliedFilters > 0
              ? `!border-primary-500 bg-primary-50 text-primary-900`
              : `border-neutral-300 dark:border-neutral-700 ${isBackgroundImage ? `text-custom-grey`:`text-neutral-700`} dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500`
          }
          `}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className={`ml-2`}>{labelTitle} ({appliedFilters})</span>
          {appliedFilters > 0 && renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {labelTitle}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Product Type</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {filterProductTypeList && filterProductTypeList.map((item) => (
                              <Radio
                                id={item.filterId.toLocaleString()}
                                key={item.filterId}
                                name="radioNameProduct"
                                label={item.filterName}
                                defaultChecked={productTypeId === item.filterId}
                                onChange={() =>
                                  handleFilterChange(
                                    "productTypeId",
                                    item.filterId
                                  )
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Product Series</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {filterProductSeriesList.map((item) => (
                              <Radio
                                id={item.filterId.toLocaleString()}
                                key={item.filterId}
                                name="radioNameSort"
                                label={item.filterName}
                                defaultChecked={
                                  productSeriesId === item.filterId
                                }
                                onChange={() => {
                                  handleFilterChange(
                                    "productSeriesId",
                                    item.filterId
                                  );
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* --------- */}
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        closeModalMoreFilter();
                        handleFilterChange("productTypeId", 0);
                        handleFilterChange("productSeriesId", 0);
                      }}
                      sizeClass="py-2.5 px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="py-2.5 px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const autoFilterDropdown = () => {
    if (searchText || productTypeId > 0 || productSeriesId > 0) {
      var getproductTypeList = filteredItems?.flatMap(x=>x.mapProdTypeArr ?? []) ?? [];
      //var getproductTypeList = filteredItems?.map((a) => a.productTypeId);
      var ptypeArr = productTypeList.filter((item) =>
        getproductTypeList?.includes(item.filterId)
      );
      setFilterProductTypeList(ptypeArr);

      var getProductSeriesId = filteredItems?.flatMap(x=>x.mapProdSeriesArr ?? []) ?? [];
      //var getProductSeriesId = filteredItems?.map((a) => a.productSeriesId);
      var seriesArr = productSeriesList.filter((item) =>
        getProductSeriesId?.includes(item.filterId)
      );
      setFilterProductSeriesList(seriesArr);
    } else {
      setFilterProductTypeList(productTypeList);
      setFilterProductSeriesList(productSeriesList);
    }
  };

  useEffect(() => {
    autoFilterDropdown();
  }, [filterParam]);

  return (
    <div className="flex lg:space-x-4">
      {/* FOR DESKTOP */}
      <div className="hidden lg:flex flex-1 space-x-4">
        <FilterRadioComponent
          filterLabel="Product Type"
          filterId={productTypeId}
          filterPropertyName="productTypeId"
          handleFilterChange={handleFilterChange}
          filterList={filterProductTypeList}
          fontAwsomeIcon={faBoxOpen}
        />
        <FilterRadioComponent
          filterLabel="Product Series"
          filterId={productSeriesId}
          filterPropertyName="productSeriesId"
          handleFilterChange={handleFilterChange}
          filterList={filterProductSeriesList}
          fontAwsomeIcon={faLayerGroup}
        />
        <div className="!ml-auto">{renderSearch()}</div>
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex flex-col overflow-x-auto lg:hidden space-x-4 space-y-4">
        {renderTabMobileFilter()}
        <div className="!ml-auto py-3">{renderSearch()}</div>
      </div>
    </div>
  );
};

export default FilterClearance;
