import axiosInstance from "api/AxiosInstance";
import CSCProductCard from "components/CSCProductCard";
import SkeletonCareer from "containers/Careers/SkeletonCareer";
import SellSheetPagination from "containers/SellSheet/SellSheetPagination";
import { BaseApiResponse } from "entities/ApiResponse";
import { BaseProp } from "entities/BaseProp";
import {
  ProductSheetDTO,
  ProductSheetFilterParameter,
} from "entities/ProductSheetDTO";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import handleScrollToEl from "utils/HandleScrollToEl";
import FilterProductSheet from "./FilterProductSheet";
import Divider from "components/Divider";
import MatchNotFound from "components/MatchNotFound";
import Heading from "components/Heading/Heading";

interface ProductSheetProp extends BaseProp {}

const ProductSheet: FC<ProductSheetProp> = ({ className = "" }) => {
  const [dataResult, setDataResult] =
    useState<BaseApiResponse<ProductSheetDTO> | null>(null);
  const [filters, setFilters] = useState<ProductSheetFilterParameter>({
    productTypeId: 0,
    departmentId: 0,
    productSeriesId: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    const response = await axiosInstance
      .get("/products/getProductSheet")
      .then((response) => {
        setDataResult(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredItems = dataResult?.data.productDataList?.filter((item) => {
    if (
      filters.departmentId &&
      (!item.mapDeptArr ||
        !item.mapDeptArr.includes(filters.departmentId))
    ) {
      return false;
    }
    if (
      filters.productSeriesId &&
      (!item.mapProdSeriesArr ||
        !item.mapProdSeriesArr.includes(filters.productSeriesId))
    ) {
      return false;
    }
    if (
      filters.productTypeId &&
      (!item.mapProdTypeArr ||
        !item.mapProdTypeArr.includes(filters.productTypeId))
    ) {
      return false;
    }
    const searchTextLower = filters.searchText?.toLowerCase();
    if (
      searchTextLower &&
      !(
        item.productName.toLowerCase().includes(searchTextLower) ||
        item.productNumber.toLowerCase().includes(searchTextLower)
      )
    ) {
      return false;
    }
    return true;
  });

  const itemsPerPage = 24;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handleScrollToEl("scrollSeriesTab");
  };

  const handleFilterChange = (
    filterName: string,
    value: number | string | undefined
  ) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
    setCurrentPage(1);
  };

  const renderMain = () => {
    return (
      <>
        {dataResult?.data && (
          <main>
            {/* FILTER */}
            <FilterProductSheet
              data={dataResult.data}
              filterParam={filters}
              handleFilterChange={handleFilterChange}
              filteredItems={filteredItems}
            />

            {/**Render product tiles */}
            {currentItems && currentItems.length > 0 ? (
              <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                {currentItems.map((item, index) => (
                  <CSCProductCard
                    name={item.productName}
                    category={item.productNumber}
                    description={item.prodDescription}
                    image={item.lowResImages || undefined}
                    link={item.fileUrl || ""}
                    key={index}
                  />
                ))}
              </div>
            ) : <MatchNotFound/>}

            {/* PAGINATION */}
            <SellSheetPagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredItems?.length as number}
              onPageChange={handlePageChange}
            />
          </main>
        )}
      </>
    );
  };

  return (
    <div className={`nc-ProductSheet ${className}`} data-nc-id="SeriesFlyers">
      <Helmet title="Product Sheets | CSC LED" />
      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm scroll-mt-32" id="scrollSeriesTab">
           <Heading
            className="text-neutral-900 dark:text-neutral-50"
            isCenter={false}
            >
            Product Sheets
            </Heading>
              {/* <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
                Product Sheets
              </h2> */}
              <span className="block mt-4 text-black-500 dark:text-black-400 text-sm sm:text-base halloween:text-black">
                From vision to illumination: Discover our amazing LED series
              </span>
          </div>
          <Divider/>
          {dataResult?.success && renderMain()}
        </div>
      </div>
    </div>
  );
};

export default ProductSheet;
