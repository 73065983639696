import { useEffect } from "react";

/**
 * Checks the app version and forces a reload if a new version is detected.
 */
const checkForUpdates = (): void => {
  const currentVersion = process.env.REACT_APP_VERSION || "0";
  const storedVersion = localStorage.getItem("appVersion");

  if(!storedVersion){
    localStorage.setItem("appVersion", currentVersion);
    window.location.reload(); // Forces a hard reload
  }
  else if (storedVersion && storedVersion !== currentVersion) {
    console.log(`New version detected: ${currentVersion}, reloading...`);
    localStorage.setItem("appVersion", currentVersion);
    window.location.reload(); // Forces a hard reload
  } else {
    localStorage.setItem("appVersion", currentVersion);
  }
};

/**
 * Custom hook to check for app updates on mount and periodically.
 */
export const useVersionCheck = (): void => {
    useEffect(() => {
      checkForUpdates();
    }, []); // Runs only on first load
};
